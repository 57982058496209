import _ from 'lodash';
import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,

  GET_PENDING_USERS_REQUEST,
  GET_PENDING_USERS_SUCCESS,
  GET_PENDING_USERS_FAILURE,

  APPROVE_PENDING_USER_REQUEST,
  APPROVE_PENDING_USER_SUCCESS,
  APPROVE_PENDING_USER_FAILURE,

  GET_BLACKLIST_USERS_REQUEST,
  GET_BLACKLIST_USERS_SUCCESS,
  GET_BLACKLIST_USERS_FAILURE,

  BLACKLIST_USER_REQUEST,
  BLACKLIST_USER_SUCCESS,
  BLACKLIST_USER_FAILURE,

  UNBLACKLIST_USER_REQUEST,
  UNBLACKLIST_USER_SUCCESS,
  UNBLACKLIST_USER_FAILURE,

  GET_USER_LOGINS_REQUEST,
  GET_USER_LOGINS_SUCCESS,
  GET_USER_LOGINS_FAILURE,

  GET_ALL_ORDERS_REQUEST,
  GET_ALL_ORDERS_SUCCESS,
  GET_ALL_ORDERS_FAILURE,

  GET_USER_ORDERS_SUCCESS,
  GET_USER_ORDERS_FAILURE,

  UPDATE_USER_ORDER_STATUS_SUCCESS,
  UPDATE_USER_ORDER_STATUS_FAILURE,

  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAILURE,

  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAILURE,

  UPLOAD_DOCUMENT_REQUEST,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAILURE,

  GET_DOCUMENT_REQUEST,
  GET_DOCUMENT_SUCCESS,
  GET_DOCUMENT_FAILURE,

  DELETE_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_FAILURE,

  GET_NEWS_REQUEST,
  GET_NEWS_SUCCESS,
  GET_NEWS_FAILURE,
  UPLOAD_PHOTO_REQUEST,
  UPLOAD_PHOTO_SUCCESS,
  UPLOAD_PHOTO_FAILURE,
  RESET_PHOTO,
  CREATE_NEWS_REQUEST,
  CREATE_NEWS_SUCCESS,
  CREATE_NEWS_FAILURE,

  DELETE_NEWS_REQUEST,
  DELETE_NEWS_SUCCESS,
  DELETE_NEWS_FAILURE,

  GET_NEWS_BY_ID_REQUEST,
  GET_NEWS_BY_ID_SUCCESS,
  GET_NEWS_BY_ID_FAILURE,

  UPDATE_NEWS_REQUEST,
  UPDATE_NEWS_SUCCESS,
  UPDATE_NEWS_FAILURE,

  RESET_NEWS,
  CHANGE_NAME,
  CHANGE_DATE,
  CHANGE_DESCRIPTION,
  UPDATE_USER_INFO_REQUEST,
  UPDATE_USER_INFO_SUCCESS,
  UPDATE_USER_INFO_FAILURE,

  GET_USER_BY_ID_REQUEST,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAILURE,

  CREATE_ORDER_REQUEST,
  CREATE_ORDER_FAILURE,
  CREATE_ORDER_SUCCESS,
  GET_ORDERS_BY_USER_ID_REQUEST,
  GET_ORDERS_BY_USER_ID_SUCCESS,
  GET_ORDERS_BY_USER_ID_FAILURE,

  GET_ORDERS_SUMMARY_REQUEST,
  GET_ORDERS_SUMMARY_SUCCESS,
  GET_ORDERS_SUMMARY_FAILURE,

  GET_INVESTMENT_ROUND_REQUEST,
  GET_INVESTMENT_ROUND_SUCCESS,
  GET_INVESTMENT_ROUND_FAILURE,
} from './actions';
import { initialState } from '../initialState';

export default function admin(state = initialState.admin, action = {}) {
  const { type, payload } = action;
  switch(type) {
    case GET_USERS_REQUEST: {
      return {
        ...state,
        status: 'running',
      };
    }

    case GET_USERS_SUCCESS: {
      return {
        ...state,
        users: payload.users,
        usersPagination: payload.users.pagination,
        status: 'idle',
      };
    }

    case GET_USERS_FAILURE: {
      return {
        ...state,
        users: {},
        status: 'idle',
      };
    }

    case GET_PENDING_USERS_REQUEST: {
      return {
        ...state,
        status: 'running',
      };
    }

    case GET_PENDING_USERS_SUCCESS: {
      return {
        ...state,
        pendingUsers: payload.pendingUsers,
        status: 'idle',
      };
    }

    case GET_PENDING_USERS_FAILURE: {
      return {
        ...state,
        status: 'idle',
      };
    }

    case APPROVE_PENDING_USER_REQUEST: {
      return {
        ...state,
        status: 'running',
      };
    }

    case APPROVE_PENDING_USER_SUCCESS: {
      return {
        ...state,
        pendingUsers: {
          ...state.pendingUsers,
          users: (state.pendingUsers.users || []).filter((e) => (e.id !== payload.approvedUserId)),
        },
        status: 'idle',
      };
    }

    case APPROVE_PENDING_USER_FAILURE: {
      return {
        ...state,
        approvedUser: {},
        status: 'idle',
      };
    }
    case GET_BLACKLIST_USERS_REQUEST: {
      return {
        ...state,
        status: 'running',
      };
    }

    case GET_BLACKLIST_USERS_SUCCESS: {
      return {
        ...state,
        blacklistUsers: payload.blacklistUsers,
        status: 'idle',
      };
    }

    case GET_BLACKLIST_USERS_FAILURE: {
      return {
        ...state,
        status: 'idle',
      };
    }

    case BLACKLIST_USER_REQUEST: {
      return {
        ...state,
        status: 'running',
      };
    }

    case BLACKLIST_USER_SUCCESS: {
      return {
        ...state,
        blacklistUsers: {
          ...state.blacklistUsers,
          users: (state.blacklistUsers.users || []).filter((e) => (e.id !== payload.blacklistedUserId)),
        },
        status: 'idle',
      };
    }

    case UNBLACKLIST_USER_FAILURE: {
      return {
        ...state,
        approvedUser: {},
        status: 'idle',
      };
    }

    case UNBLACKLIST_USER_REQUEST: {
      return {
        ...state,
        status: 'running',
      };
    }

    case UNBLACKLIST_USER_SUCCESS: {
      return {
        ...state,
        blacklistUsers: {
          ...state.blacklistUsers,
          users: (state.blacklistUsers.users || []).filter((e) => (e.id !== payload.blacklistedUserId)),
        },
        status: 'idle',
      };
    }

    case BLACKLIST_USER_FAILURE: {
      return {
        ...state,
        blacklistUsers: {},
        status: 'idle',
      };
    }

    case GET_USER_LOGINS_REQUEST: {
      return {
        ...state,
        status: 'running',
      };
    }

    case GET_USER_LOGINS_SUCCESS: {
      return {
        ...state,
        userLogins: payload.users,
        userLoginsPagination: payload.users.pagination,
        status: 'idle',
      };
    }

    case GET_USER_LOGINS_FAILURE: {
      return {
        ...state,
        userLogins: {},
        status: 'idle',
      };
    }

    case GET_USER_ORDERS_SUCCESS: {
      return {
        ...state,
        userOrders: payload.ordersUserList,
      };
    }

    case GET_USER_ORDERS_FAILURE: {
      return {
        ...state,
        userOrders: [],
      };
    }

    case GET_ALL_ORDERS_REQUEST: {
      const key = payload.isLocked ? 'nonTradableOrders' : 'tradableOrders';
      return {
        ...state,
        [key]: {
          ...state[key],
          status: 'running',
        },
      };
    }

    case GET_ALL_ORDERS_SUCCESS: {
      const key = payload.params.isLocked ? 'nonTradableOrders' : 'tradableOrders';

      return {
        ...state,
        // orders: {
        //   ordersList: payload.data.orders,
        //   pagination: payload.data.pagination,
        // },
        [key]: {
          ordersList: payload.data.orders,
          pagination: payload.data.pagination,
          status: 'idle',
        },
      };
    }

    case GET_ALL_ORDERS_FAILURE: {
      const key = payload.params.isLocked ? 'nonTradableOrders' : 'tradableOrders';

      return {
        ...state,
        [key]: {
          ...state[key],
          status: 'idle',
        },
      };
    }

    case UPDATE_USER_ORDER_STATUS_SUCCESS: {
      return {
        ...state,
        statusUpload: payload,
      };
    }

    case UPDATE_USER_ORDER_STATUS_FAILURE: {
      return {
        ...state,
        statusUpload: payload,
      };
    }

    case UPDATE_ORDER_REQUEST: {
      return {
        ...state,
        status: 'running',
      };
    }

    case UPDATE_ORDER_SUCCESS: {
      return {
        ...state,
        status: 'idle',
      };
    }

    case UPDATE_ORDER_FAILURE: {
      return {
        ...state,
        status: 'idle',
      };
    }

    case DELETE_ORDER_REQUEST: {
      return {
        ...state,
        status: 'running',
      };
    }

    case DELETE_ORDER_SUCCESS: {
      return {
        ...state,
        status: 'idle',
      };
    }

    case DELETE_ORDER_FAILURE: {
      return {
        ...state,
        status: 'idle',
      };
    }

    // upload document
    case UPLOAD_DOCUMENT_REQUEST: {
      return {
        ...state,
        status: 'running',
        uploading: true,
      };
    }

    case UPLOAD_DOCUMENT_SUCCESS: {
      return {
        ...state,
        status: 'idle',
        uploading: false,
        documents: [
          ...state.documents,
          payload.newDocument,
        ],

      };
    }

    case UPLOAD_DOCUMENT_FAILURE: {
      return {
        ...state,
        uploading: false,
        status: 'idle',
      };
    }

    // get document
    case GET_DOCUMENT_REQUEST: {
      return {
        ...state,
        status: 'running',
        documents: [],
      };
    }

    case GET_DOCUMENT_SUCCESS: {
      return {
        ...state,
        status: 'idle',
        documents: payload.documents,
      };
    }

    case GET_DOCUMENT_FAILURE: {
      return {
        ...state,
        status: 'idle',
        documents: [],
      };
    }

    // delete document
    case DELETE_DOCUMENT_REQUEST: {
      return {
        ...state,
        status: 'running',
      };
    }
    // It isn't implemented yet to remove item from document redux store.
    case DELETE_DOCUMENT_SUCCESS: {
      return {
        ...state,
        status: 'idle',
        documents: _.filter(
          state.documents,
          (o) => o.id !== payload.documentId,
        ),
      };
    }

    case DELETE_DOCUMENT_FAILURE: {
      return {
        ...state,
        status: 'idle',
      };
    }

    case GET_NEWS_REQUEST: {
      return {
        ...state,
        status: 'running',
        news: [],
      };
    }

    case GET_NEWS_SUCCESS: {
      return {
        ...state,
        status: 'idle',
        news: payload.news,
      };
    }

    case UPDATE_USER_INFO_REQUEST: {
      return {
        ...state,
        status: 'running',
      };
    }

    case UPDATE_USER_INFO_SUCCESS: {
      const cloneState = _.cloneDeep(state);
      _.set(cloneState, 'ordersByUserId.user', payload);
      _.set(cloneState, 'status', 'idle');
      return cloneState;
    }

    case UPDATE_USER_INFO_FAILURE: {
      return {
        ...state,
        status: 'idle',
      };
    }

    case GET_USER_BY_ID_REQUEST: {
      return {
        ...state,
        status: 'running',
        userInfo: initialState.admin.userInfo,
      };
    }

    case GET_USER_BY_ID_SUCCESS: {
      return {
        ...state,
        status: 'idle',
        userInfo: payload,
      };
    }

    case GET_USER_BY_ID_FAILURE: {
      return {
        ...state,
        status: 'idle',
      };
    }

    case GET_NEWS_FAILURE: {
      return {
        ...state,
        status: 'idle',
        news: [],
      };
    }

    case UPLOAD_PHOTO_REQUEST: {
      return {
        ...state,
        draftNews: {
          ...state.draftNews,
          photo: null,
          imageLoading: true,
        },
      };
    }

    case UPLOAD_PHOTO_SUCCESS: {
      return {
        ...state,
        draftNews: {
          ...state.draftNews,
          photo: payload.photo,
          imageLoading: false,
        },
      };
    }

    case UPLOAD_PHOTO_FAILURE: {
      return {
        ...state,
        draftNews: {
          ...state.draftNews,
          photo: null,
          imageLoading: false,
        },
      };
    }

    case CREATE_NEWS_REQUEST: {
      return {
        ...state,
        status: 'running',
      };
    }

    case CREATE_NEWS_SUCCESS: {
      return {
        ...state,
        status: 'idle',
        photo: null,
      };
    }

    case CREATE_NEWS_FAILURE: {
      return {
        ...state,
        status: 'idle',
      };
    }

    case RESET_PHOTO: {
      return {
        ...state,
        draftNews: {
          ...state.draftNews,
          photo: null,
          imageLoading: false,
        },
      };
    }

    case DELETE_NEWS_REQUEST: {
      return {
        ...state,
        status: 'running',
      };
    }

    case DELETE_NEWS_SUCCESS: {
      const { newsId } = payload;
      const { news } = state;
      _.remove(news, (item) => item.id === newsId);
      return {
        ...state,
        news,
        status: 'idle',
      };
    }

    case DELETE_NEWS_FAILURE: {
      return {
        ...state,
        status: 'idle',
      };
    }

    case GET_NEWS_BY_ID_REQUEST: {
      return {
        ...state,
        status: 'running',
        draftNews: { ...initialState.admin.draftNews },
      };
    }

    case GET_NEWS_BY_ID_SUCCESS: {
      const { news } = payload;
      return {
        ...state,
        status: 'idle',
        draftNews: {
          ...news,
          imageLoading: false,
        },
      };
    }

    case GET_NEWS_BY_ID_FAILURE: {
      return {
        ...state,
        status: 'idle',
      };
    }

    case UPDATE_NEWS_REQUEST: {
      return {
        ...state,
        status: 'running',
      };
    }

    case UPDATE_NEWS_SUCCESS: {
      return {
        ...state,
        status: 'idle',
      };
    }

    case UPDATE_NEWS_FAILURE: {
      return {
        ...state,
        status: 'idle',
      };
    }

    case RESET_NEWS: {
      return {
        ...state,
        draftNews: { ...initialState.admin.draftNews },
      };
    }

    case CHANGE_NAME: {
      return {
        ...state,
        draftNews: {
          ...state.draftNews,
          name: payload.name,
        },
      };
    }

    case CHANGE_DATE: {
      return {
        ...state,
        draftNews: {
          ...state.draftNews,
          date: payload.date,
        },
      };
    }

    case CHANGE_DESCRIPTION: {
      return {
        ...state,
        draftNews: {
          ...state.draftNews,
          description: payload.description,
        },
      };
    }

    case CREATE_ORDER_REQUEST: {
      return {
        ...state,
        status: 'running',
      };
    }

    case CREATE_ORDER_SUCCESS: {
      const { ordersByUserId } = state;
      const { user, orders } = ordersByUserId;
      const t = orders.slice();
      t.push(payload);
      return {
        ...state,
        ordersByUserId: {
          ...ordersByUserId,
          orders: t,
          user: {...user, orders: t},
        },
        status: 'idle',
      };
    }

    case CREATE_ORDER_FAILURE: {
      return {
        ...state,
        status: 'idle',
      };
    }

    case GET_ORDERS_BY_USER_ID_REQUEST: {
      return {
        ...state,
        status: 'running',
        ordersByUserId: initialState.admin.ordersByUserId,
      };
    }
    case GET_ORDERS_BY_USER_ID_SUCCESS: {
      return {
        ...state,
        status: 'idle',
        ordersByUserId: payload,
      };
    }
    case GET_ORDERS_BY_USER_ID_FAILURE: {
      return {
        ...state,
        status: 'idle',
      };
    }

    case GET_ORDERS_SUMMARY_REQUEST: {
      return state;
    }

    case GET_ORDERS_SUMMARY_SUCCESS: {
      return {
        ...state,
        summary: payload,
      };
    }

    case GET_ORDERS_SUMMARY_FAILURE: {
      return state;
    }

    case GET_INVESTMENT_ROUND_REQUEST: {
      return state;
    }

    case GET_INVESTMENT_ROUND_SUCCESS: {
      return {
        ...state,
        investmentRound: payload,
      };
    }

    case GET_INVESTMENT_ROUND_FAILURE: {
      return state;
    }

    default: {
      return state;
    }
  }
}
